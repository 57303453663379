body {
  min-height: 100%;
  margin: 0;
  overflow-x: hidden;
}
body #root {
  width: 100%;
  overflow: hidden;
  min-height: 100%;
}
body .webPage {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  z-index: 1;
}

*::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: main;
  src: url("./CaslonAntique.ttf");
}
.app {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  color: ghostwhite;
  text-align: center;
  font-family: main;
}
.app img {
  background-color: ghostwhite;
}
.app .blog {
  bottom: calc(var(--vh, 1vh) * 80);
  left: calc(100vw - 20vh);
}
.app .aboutMe {
  bottom: calc(var(--vh, 1vh) * 80);
  right: calc(100vw - 20vh);
}
.app .career {
  top: calc(var(--vh, 1vh) * 80);
  right: calc(100vw - 20vh);
}
.app .gallery {
  top: calc(var(--vh, 1vh) * 80);
  left: calc(100vw - 20vh);
}
@media screen and (orientation: portrait) {
  .app .blog {
    bottom: calc(var(--vh, 1vh) * 88);
    left: calc(100vw - 12vh);
  }
  .app .aboutMe {
    bottom: calc(var(--vh, 1vh) * 88);
    right: calc(100vw - 12vh);
  }
  .app .career {
    top: calc(var(--vh, 1vh) * 88);
    right: calc(100vw - 12vh);
  }
  .app .gallery {
    top: calc(var(--vh, 1vh) * 88);
    left: calc(100vw - 12vh);
  }
}

html {
  background: black;
}

body {
  background-image: url("./svgs/svgback.svg");
  background-size: 25vw;
}
body.blog {
  background-color: #00ffff;
}
body.blog #blogNav {
  background: magenta;
}
body.blog .app {
  transform: translateY(calc(var(--vh, 1vh) * 80)) translateX(calc(-100vw + var(--vh, 1vh) * 20 - 1px));
}
body.aboutMe {
  background-color: #ff00ff;
}
body.aboutMe #aboutMeNav {
  background: cyan;
  color: black;
}
body.aboutMe .app {
  transform: translateY(calc(var(--vh, 1vh) * 80)) translateX(calc(100vw - 20vh + 1px));
}
body.career {
  background-color: #ff0022;
}
body.career #careerNav {
  background: blue;
}
body.career .app {
  transform: translateY(calc(var(--vh, 1vh) * -80)) translateX(calc(100vw - 20vh + 1px));
}
body.gallery {
  background-color: #4000ff;
}
body.gallery #galleryNav {
  background: red;
}
body.gallery .app {
  transform: translateY(calc(var(--vh, 1vh) * -80)) translateX(calc(-100vw + 20vh - 1px));
}
body.shift1F {
  animation: hueShift1F 0.75s linear forwards;
}
body.shift1F #blogNav {
  background: magenta;
}
body.shift1F .app {
  animation: shift1F 0.75s ease forwards;
}
body.shift1B {
  animation: hueShift1B 0.75s linear forwards;
}
body.shift1B .app {
  animation: shift1B 0.75s ease forwards;
}
body.shift2F {
  animation: hueShift2F 0.75s linear forwards;
}
body.shift2F #aboutMeNav {
  background: cyan;
  color: black;
}
body.shift2F .app {
  animation: shift2F 0.75s ease forwards;
}
body.shift2B {
  animation: hueShift2B 0.75s linear forwards;
}
body.shift2B .app {
  animation: shift2B 0.75s ease forwards;
}
body.shift3F {
  animation: hueShift4F 0.75s linear forwards;
}
body.shift3F #careerNav {
  background: blue;
}
body.shift3F .app {
  animation: shift3F 0.75s ease forwards;
}
body.shift3B {
  animation: hueShift4B 0.75s linear forwards;
}
body.shift3B .app {
  animation: shift3B 0.75s ease forwards;
}
body.shift4F {
  animation: hueShift3F 0.75s linear forwards;
}
body.shift4F #galleryNav {
  background: red;
}
body.shift4F .app {
  animation: shift4F 0.75s ease forwards;
}
body.shift4B {
  animation: hueShift3B 0.75s linear forwards;
}
body.shift4B .app {
  animation: shift4B 0.75s ease forwards;
}
@keyframes hueShift1F {
  0% {
    background-color: black;
  }
  100% {
    background-color: #00ffff;
  }
}
@keyframes hueShift1B {
  0% {
    background-color: #00ffff;
  }
  100% {
    background-color: black;
  }
}
@keyframes hueShift2F {
  0% {
    background-color: black;
  }
  100% {
    background-color: #ff00ff;
  }
}
@keyframes hueShift2B {
  0% {
    background-color: #ff00ff;
  }
  100% {
    background-color: black;
  }
}
@keyframes hueShift3F {
  0% {
    background-color: black;
  }
  100% {
    background-color: #4000ff;
  }
}
@keyframes hueShift3B {
  0% {
    background-color: #4000ff;
  }
  100% {
    background-color: black;
  }
}
@keyframes hueShift4F {
  0% {
    background-color: black;
  }
  100% {
    background-color: #ff0022;
  }
}
@keyframes hueShift4B {
  0% {
    background-color: #ff0022;
  }
  100% {
    background-color: black;
  }
}
@keyframes shift1F {
  0% {
    transform: translateX(0) translateY(0);
  }
  100% {
    transform: translateY(calc(var(--vh, 1vh) * 80)) translateX(calc(-100vw + 20vh));
  }
}
@keyframes shift1B {
  0% {
    transform: translateY(calc(var(--vh, 1vh) * 80)) translateX(calc(-100vw + 20vh));
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}
@keyframes shift2F {
  0% {
    transform: translateX(0) translateY(0);
  }
  100% {
    transform: translateY(calc(var(--vh, 1vh) * 80)) translateX(calc(100vw - 20vh));
  }
}
@keyframes shift2B {
  0% {
    transform: translateY(calc(var(--vh, 1vh) * 80)) translateX(calc(100vw - 20vh));
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}
@keyframes shift3F {
  0% {
    transform: translateX(0) translateY(0);
  }
  100% {
    transform: translateY(calc(var(--vh, 1vh) * -80)) translateX(calc(100vw - 20vh));
  }
}
@keyframes shift3B {
  0% {
    transform: translateY(calc(var(--vh, 1vh) * -80)) translateX(calc(100vw - 20vh));
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}
@keyframes shift4F {
  0% {
    transform: translateX(0) translateY(0);
  }
  100% {
    transform: translateY(calc(var(--vh, 1vh) * -80)) translateX(calc(-100vw + 20vh));
  }
}
@keyframes shift4B {
  0% {
    transform: translateY(calc(var(--vh, 1vh) * -80)) translateX(calc(-100vw + 20vh));
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}
@media screen and (orientation: portrait) {
  body.blog .app {
    transform: translateY(calc(var(--vh, 1vh) * 88)) translateX(calc(-100vw + 12vh));
  }
  body.aboutMe .app {
    transform: translateY(calc(var(--vh, 1vh) * 88)) translateX(calc(100vw - 12vh));
  }
  body.career .app {
    transform: translateY(calc(var(--vh, 1vh) * -88)) translateX(calc(100vw - 12vh));
  }
  body.gallery .app {
    transform: translateY(calc(var(--vh, 1vh) * -88)) translateX(calc(-100vw + 12vh));
  }
  @keyframes shift1F {
    0% {
      transform: translateX(0) translateY(0);
    }
    100% {
      transform: translateY(calc(var(--vh, 1vh) * 88)) translateX(calc(-100vw + 12vh));
    }
  }
  @keyframes shift1B {
    0% {
      transform: translateY(calc(var(--vh, 1vh) * 88)) translateX(calc(-100vw + 12vh));
    }
    100% {
      transform: translateX(0) translateY(0);
    }
  }
  @keyframes shift2F {
    0% {
      transform: translateX(0) translateY(0);
    }
    100% {
      transform: translateY(calc(var(--vh, 1vh) * 88)) translateX(calc(100vw - 12vh));
    }
  }
  @keyframes shift2B {
    0% {
      transform: translateY(calc(var(--vh, 1vh) * 88)) translateX(calc(100vw - 12vh));
    }
    100% {
      transform: translateX(0) translateY(0);
    }
  }
  @keyframes shift3F {
    0% {
      transform: translateX(0) translateY(0);
    }
    100% {
      transform: translateY(calc(var(--vh, 1vh) * -88)) translateX(calc(100vw - 12vh));
    }
  }
  @keyframes shift3B {
    0% {
      transform: translateY(calc(var(--vh, 1vh) * -88)) translateX(calc(100vw - 12vh));
    }
    100% {
      transform: translateX(0) translateY(0);
    }
  }
  @keyframes shift4F {
    0% {
      transform: translateX(0) translateY(0);
    }
    100% {
      transform: translateY(calc(var(--vh, 1vh) * -88)) translateX(calc(-100vw + 12vh));
    }
  }
  @keyframes shift4B {
    0% {
      transform: translateY(calc(var(--vh, 1vh) * -88)) translateX(calc(-100vw + 12vh));
    }
    100% {
      transform: translateX(0) translateY(0);
    }
  }
}

.navSquare {
  width: calc(var(--vh, 1vh) * 20);
  height: calc(var(--vh, 1vh) * 20);
  font-size: calc(var(--vh, 1vh) * 6.5);
  z-index: 2;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin: 0;
  user-select: none;
  cursor: pointer;
}
@media screen and (orientation: portrait) {
  .navSquare {
    width: 12vh;
    height: calc(var(--vh, 1vh) * 12);
    font-size: calc(var(--vh, 1vh) * 3);
  }
}
.navSquare#blogNav {
  right: 0;
  background: #00ffff;
}
.navSquare#aboutMeNav {
  top: 0;
  left: 0;
  background: #ff00ff;
}
.navSquare#galleryNav {
  right: 0;
  bottom: 0;
  background: #4000ff;
}
.navSquare#careerNav {
  background: #ff0022;
  bottom: 0;
  left: 0;
}

.slider {
  width: 37%;
  height: 14px;
  position: relative;
  background-color: ghostwhite;
  box-sizing: border-box;
  border-radius: 15px;
  touch-action: none;
  user-select: none;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width: 1285px) {
  .slider {
    width: 40%;
  }
}
@media screen and (max-width: 1075px) {
  .slider {
    width: 45%;
  }
}
.slider:hover {
  cursor: pointer;
}
.slider:active {
  cursor: pointer;
}
.slider .knob {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(-50%);
  background: #111;
  box-sizing: border-box;
}

.aboutMe {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}
.aboutMe .slider {
  position: absolute;
  margin-top: 25px;
}
.aboutMe .info {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  position: absolute;
  top: 7vw;
  font-size: 25px;
  margin-top: 85px;
  padding-bottom: calc(var(--vh, 1vh) * 21);
}
@media screen and (max-width: 500px) {
  .aboutMe .info img {
    width: 25vw;
    height: 25vw;
  }
}
@media screen and (orientation: portrait) {
  .aboutMe .info {
    padding-bottom: calc(var(--vh, 1vh) * 13);
  }
}
.aboutMe .info img {
  width: 28vw;
  height: 28vw;
  border-radius: 100%;
  object-fit: cover;
}
.aboutMe .info p {
  height: fit-content;
  width: 30%;
  word-wrap: break-word;
  overflow-y: scroll;
  margin: 0;
  margin-top: 7vw;
  padding: 10px;
  background: aqua;
}
@media screen and (max-width: 1000px) {
  .aboutMe .info p {
    width: 48%;
    margin: 0;
  }
}
@media screen and (max-width: 500px) {
  .aboutMe .info p {
    width: 96%;
    margin: 0;
  }
  .aboutMe .info img {
    width: 50vw;
    height: 50vw;
  }
}

.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white !important;
}
.blog .tab {
  display: inline-block;
  margin-left: 20px;
}
.blog #blogPostCont {
  width: 70%;
  height: calc(var(--vh, 1vh) * 60);
  text-align: left;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  word-wrap: break-word;
  background: #f0f;
  padding: 25px;
  margin-bottom: calc(var(--vh, 1vh) * 21);
}
@media screen and (orientation: portrait) {
  .blog #blogPostCont {
    margin-bottom: calc(var(--vh, 1vh) * 13);
  }
}
.blog #blogPostCont * {
  font-size: 30px;
  width: 100%;
  margin: 0;
}
.blog .slider {
  margin-top: calc(var(--vh, 1vh) * 5);
  margin-bottom: calc(var(--vh, 1vh) * 5);
}
.blog .slideDown1 {
  animation: slideDown1 0.5s ease forwards;
}
.blog .slideDown2 {
  animation: slideDown2 0.5s ease forwards;
}
.blog .slideUp1 {
  animation: slideUp1 0.5s ease forwards;
}
.blog .slideUp2 {
  animation: slideUp2 0.5s ease forwards;
}
@keyframes slideDown1 {
  0% {
    opacity: 0.2;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideDown2 {
  0% {
    opacity: 0.2;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideUp1 {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideUp2 {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.career {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.career #career {
  width: 76vw;
  height: 43vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: calc((var(--vh, 1vh) * 100 - 38vw) / 2);
}
.career #career #careerCanvCont {
  width: 38vw;
  height: 38vw;
  position: relative;
}
.career #career #careerCanvCont #arrowLeft {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 0;
  right: 95px;
  background-image: url("svgs/leftArrow.svg");
  background-size: cover;
  user-select: none;
  cursor: pointer;
}
.career #career #careerCanvCont #arrowRight {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 0;
  right: 10px;
  background-image: url("svgs/rightArrow.svg");
  background-size: cover;
  user-select: none;
  cursor: pointer;
}
.career #career #careerTextCont {
  width: 38vw;
  height: 38vw;
  overflow-y: scroll;
  background: white;
  color: black;
  font-size: 28px;
}
.career #career #careerTextCont p {
  width: 95.5%;
  margin: auto;
}
.career #career #careerTextCont p h3 {
  margin: 11px;
}
.career #career #careerTextCont .appear1 {
  animation: appear1 0.5s ease forwards;
}
.career #career #careerTextCont .appear2 {
  animation: appear2 0.5s ease forwards;
}
@keyframes appear1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes appear2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (orientation: portrait) {
  .career #career {
    width: 80vw;
    height: fit-content;
    margin-top: calc(var(--vh, 1vh) * 12 + 10vw);
  }
  .career #career #careerCanvCont {
    width: 80vw;
    height: 80vw;
  }
  .career #career #careerTextCont {
    width: 80vw;
    height: fit-content;
    background: transparent;
    color: white;
  }
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home main {
  width: calc(100% - var(--vh, 1vh) * 40);
  height: 100%;
  word-wrap: break-word;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 27px;
  box-sizing: border-box;
  padding: 50px;
  padding-bottom: 25px;
  text-shadow: 0px 1px 0px black, 0px -1px 0px black, 1px 0px 0px black, -1px 0px 0px black;
}
@media screen and (orientation: portrait) {
  .home main {
    width: calc(100% - 12vh + 10px);
    height: calc(100% - var(--vh, 1vh) * 12 + 10px);
  }
}
.home main h1 {
  margin-top: 10px;
  border: 2px solid white;
}
.home main h1,
.home main p {
  padding: 5px 10px 5px 10px;
  height: fit-content;
  width: fit-content;
  margin: auto;
  background: rgba(0, 0, 0, 0.75);
}
.home main p {
  border: 3px solid white;
}

.gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gallery #galText {
  font-size: 24px;
  width: 70%;
  word-wrap: break-word;
  position: absolute;
  top: calc(var(--vh, 1vh) * 83.5 + 47px);
  padding-bottom: 10px;
}
@media screen and (orientation: portrait) {
  .gallery #galText {
    top: 50%;
    transform: translateY(40vw);
  }
}
.gallery #galText #galGitHub {
  font-size: 50px;
  color: white;
}

.img-orbit {
  --unit: var(--vh, 1vh);
  width: calc(var(--unit) * 100);
  height: calc(var(--unit) * 77 + 47px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media screen and (orientation: portrait) {
  .img-orbit {
    --unit: 1vw;
  }
}
.img-orbit img {
  border-radius: 100%;
  object-fit: cover;
  border: 5px solid white;
}
.img-orbit .current {
  width: calc(var(--unit) * 54);
  height: calc(var(--unit) * 54);
  position: absolute;
  bottom: 47px;
}
.img-orbit .current img {
  width: 100%;
  height: 100%;
}
.img-orbit .satel {
  width: calc(var(--unit) * 20.5);
  height: calc(var(--unit) * 20.5);
  position: absolute;
  transform-origin: center;
  bottom: calc(var(--unit) * 27 + 47px);
  z-index: 1;
}
.img-orbit .satel:nth-of-type(1) {
  transform: translateY(50%) rotateZ(0) translateX(calc(var(--unit) * -39));
  z-index: 0;
}
.img-orbit .satel:nth-of-type(2) {
  transform: translateY(50%) translateX(calc(var(--unit) * -39));
}
.img-orbit .satel:nth-of-type(3) {
  transform: translateY(50%) rotateZ(45deg) translateX(calc(var(--unit) * -39)) rotateZ(-45deg);
}
.img-orbit .satel:nth-of-type(4) {
  transform: translateY(50%) rotateZ(90deg) translateX(calc(var(--unit) * -39)) rotateZ(-90deg);
}
.img-orbit .satel:nth-of-type(5) {
  transform: translateY(50%) rotateZ(135deg) translateX(calc(var(--unit) * -39)) rotateZ(-135deg);
}
.img-orbit .satel:nth-of-type(6) {
  transform: translateY(50%) rotateZ(180deg) translateX(calc(var(--unit) * -39)) rotateZ(-180deg);
  z-index: 0;
}
.img-orbit .satel.rotateF1 {
  animation: rotateF1 0.4s forwards;
}
.img-orbit .satel.rotateF2 {
  animation: rotateF2 0.4s forwards;
}
.img-orbit .satel.rotateF3 {
  animation: rotateF3 0.4s forwards;
}
.img-orbit .satel.rotateF4 {
  animation: rotateF4 0.4s forwards;
}
@keyframes rotateF1 {
  0% {
    transform: translateY(50%) rotateZ(0) rotateZ(0) translateX(calc(var(--unit) * -39)) rotateZ(0);
  }
  100% {
    transform: translateY(50%) rotateZ(45deg) translateX(calc(var(--unit) * -39)) rotateZ(-45deg);
  }
}
@keyframes rotateF2 {
  0% {
    transform: translateY(50%) rotateZ(45deg) translateX(calc(var(--unit) * -39)) rotateZ(-45deg);
  }
  100% {
    transform: translateY(50%) rotateZ(90deg) translateX(calc(var(--unit) * -39)) rotateZ(-90deg);
  }
}
@keyframes rotateF3 {
  0% {
    transform: translateY(50%) rotateZ(90deg) translateX(calc(var(--unit) * -39)) rotateZ(-90deg);
  }
  100% {
    transform: translateY(50%) rotateZ(135deg) translateX(calc(var(--unit) * -39)) rotateZ(-135deg);
  }
}
@keyframes rotateF4 {
  0% {
    transform: translateY(50%) rotateZ(135deg) translateX(calc(var(--unit) * -39)) rotateZ(-135deg);
  }
  100% {
    transform: translateY(50%) rotateZ(180deg) translateX(calc(var(--unit) * -39)) rotateZ(-180deg);
  }
}
.img-orbit .satel.rotateB1 {
  animation: rotateB1 0.4s forwards;
}
.img-orbit .satel.rotateB2 {
  animation: rotateB2 0.4s forwards;
}
.img-orbit .satel.rotateB3 {
  animation: rotateB3 0.4s forwards;
}
.img-orbit .satel.rotateB4 {
  animation: rotateB4 0.4s forwards;
}
@keyframes rotateB1 {
  0% {
    transform: translateY(50%) rotateZ(45deg) translateX(calc(var(--unit) * -39)) rotateZ(-45deg);
  }
  100% {
    transform: translateY(50%) rotateZ(0) translateX(calc(var(--unit) * -39)) rotateZ(0);
  }
}
@keyframes rotateB2 {
  0% {
    transform: translateY(50%) rotateZ(90deg) translateX(calc(var(--unit) * -39)) rotateZ(-90deg);
  }
  100% {
    transform: translateY(50%) rotateZ(45deg) translateX(calc(var(--unit) * -39)) rotateZ(-45deg);
  }
}
@keyframes rotateB3 {
  0% {
    transform: translateY(50%) rotateZ(135deg) translateX(calc(var(--unit) * -39)) rotateZ(-135deg);
  }
  100% {
    transform: translateY(50%) rotateZ(90deg) translateX(calc(var(--unit) * -39)) rotateZ(-90deg);
  }
}
@keyframes rotateB4 {
  0% {
    transform: translateY(50%) rotateZ(180deg) translateX(calc(var(--unit) * -39)) rotateZ(-180deg);
  }
  100% {
    transform: translateY(50%) rotateZ(135deg) translateX(calc(var(--unit) * -39)) rotateZ(-135deg);
  }
}
.img-orbit .satel img {
  width: 100%;
  height: 100%;
}
.img-orbit .orbiters {
  width: 150px;
  height: 47px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
  font-size: 15px;
}
.img-orbit .orbiters .orbitArrow {
  width: 47px;
  height: 47px;
  cursor: pointer;
}
.img-orbit .orbiters .orbitArrow:nth-of-type(1) {
  background-image: url("svgs/leftArrow.svg");
  background-size: cover;
}
.img-orbit .orbiters .orbitArrow:nth-of-type(2) {
  background-image: url("svgs/rightArrow.svg");
  background-size: cover;
}
.img-orbit .orbiters h1 {
  margin: 0;
}